import React, { useState, useEffect } from "react"

const Footer = ({}) => {
  // useEffect(() => {
  //   let cpBtn = document.querySelector(".cp-btn")
  //   let email = document.querySelector(".em-ipt")
  //   cpBtn.addEventListener("click", () => {
  //     email.focus()
  //     email.select()
  //     try {
  //       let successful = document.execCommand("copy")
  //       let msg = successful ? "successful" : "unsuccessful"
  //       console.log("Copying text command was " + msg)
  //     } catch (err) {
  //       console.log("Oops, unable to copy")
  //     }
  //     cpBtn.innerHTML = "Copied!"
  //   })
  // })

  return (
    <>
      <footer className="bg-li py-5">
        <div className="container py-xl-5 py-lg-3">
          <div className="subscribe mx-auto">
            <h2 className="tittle text-center font-weight-bold"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="1500"
            data-sal-easing="ease-out-back">
              Got <span className="title-span">Questions?</span>
            </h2>
            <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="1500"
            data-sal-easing="ease-out-back">
            Feel free to reach out, we will reply to you with even the tiniest questions!

            </p>
            {/* <div className="row">
              <div className="bg-danger col-md-6 col-lg-3 col-xs-6 col-sm-6 ">
                <div className="icon-effect-w3">
                  <span class="fa solid fa-phone"></span>
                </div>
              </div>
              <div className="bg-warning col-md-6 col-lg-3 col-sm-6  col-xs-2 ">
                <div className="icon-effect-w3">
                  <span class="fa solid fa-phone"></span>
                </div>
              </div>
            </div> */}
            <div
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="1500"
            data-sal-easing="ease-out-back"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "40%",
                }}
              >
                <a href="tel:+1-437-421-1707">
                  <div className="icon-effect-w3">
                    <span class="fa solid fa-phone"></span>
                  </div>
                </a>

                <a href="mailto:hello@codingchamps.ca">
                  <div className="icon-effect-w3" style={{marginLeft:"12px"}}>
                    <span className="fa fa-envelope"></span>
                  </div>
                </a>

                <a href="https://www.facebook.com/codingchamps.ca">
                  <div className="icon-effect-w3" style={{marginLeft:"12px"}}>
                    <span className="fab fa-facebook"></span>
                  </div>
                </a>
              </div>
            </div>

            {/* <form action="#" method="post" className="subscribe-wthree pt-2">
              <div className="d-flex subscribe-wthree-field">
                <input
                  className="form-control em-ipt"
                  type="email"
                  defaultValue="lwz7512@gmail.com"
                  name="email"
                  required=""
                />
                <button className="btn form-control w-50 cp-btn" type="button">
                  Copy
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </footer>

      <div className="copy-bottom bg-li py-4 border-top">
        <div className="container-fluid">
          <div className="d-md-flex px-md-3 position-relative text-center">
            {/* <div className="social-icons-footer mb-md-0 mb-3">
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <span className="fab fa-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="fab fa-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="fab fa-google-plus"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="fab fa-instagram"></span>
                  </a>
                </li>
              </ul>
            </div> */}

            <div className="copy_right mx-md-auto mb-md-0 mb-3">
              <p className="text-bl let">
                © 2022 All rights reserved by <b>Coding Champs</b>
                {/* <a href="http://w3layouts.com/" target="_blank">
                  W3layouts
                </a> */}
              </p>
            </div>

            {/* <a href="#home" className="move-top text-center">
              <i className="fa fa-level-up-alt" aria-hidden="true"></i>
            </a> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
